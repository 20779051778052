import React from 'react'

const Accomidation = () => {
  return (
    <div>
    <h2>Accommodation</h2>
    <p>Accommodation tab content goes here.</p>
  </div>
  )
}

export default Accomidation
