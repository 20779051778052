import React from 'react'

const Depart = () => {
  return (
    <div>
    <h2>Depart</h2>
    <p>Depart tab content goes here.</p>
  </div>
  )
}

export default Depart
